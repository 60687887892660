@import 'ngx-toastr/toastr';

.toast-container {
  pointer-events: unset;
}

.toast-container .ngx-toastr.toast-error {
  background-color: var(--vermillon-500);
  background-image: none;
  padding: 15px;
}

/*
  Adjusting the ngx-toastr position classes a bit to make them work with the snackbar,
  they should not break the stock toast.
 */
.toast-container > bgo-toast {
  background-color: unset;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toast-custom-top-center {
  top: 50px;
  right: 0;
  width: 100%;
}

.toast-container.toast-custom-top-center .ngx-toastr {
  margin-left: auto;
  margin-right: auto;
  @apply w-[300px] sm:w-[500px] md:w-[620px] lg:w-[988px];
}

.toastr-small {
  @apply w-[250px] text-sm !important;
}
