/*
  Font awesome icons are rendered as their original size on the server, making them very big and space out the entire UI
  This is a workaround suggested here: https://github.com/FortAwesome/angular-fontawesome/issues/48
*/
fa-icon svg {
  display: inline-block;
  font-size: inherit;
  height: 1rem;
}

fa-icon .fa-2x {
  height: 2rem;
}
