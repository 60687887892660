@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
}
@keyframes slide-down {
  to {
    transform: translateY(100%);
  }
}

::view-transition-old(search-transition),
::view-transition-new(search-transition) {
  animation-duration: 250ms;
  animation-name: -ua-view-transition-fade-in, slide-up;
}
::view-transition-old(search-transition) {
  animation-name: -ua-view-transition-fade-out, slide-down;
}
