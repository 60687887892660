:root {
  --color-primary: var(--president-500);
  --color-secondary: #fff;
  --footer-height: 22rem;
  --minimalist-footer-height: 12rem;
  --navbar-height: 4.5rem;
  --navbar-height-with-addition: calc(var(--navbar-height) + 0.5rem);
  --navbar-height-mobile: 5.0625rem;
  --navbar-height-mobile-with-addition: calc(var(--navbar-height-mobile) + 2.5625rem);
  --v-layout-top: 2.5rem;
  --v-layout-bottom: 11rem;

  --erneuerbarheizen-primary-color: #09924b;

  --president-50: #f8fafc;
  --president-100: #ddeaf7;
  --president-200: #a6b9cf;
  --president-300: #738ca8;
  --president-400: #3f5774;
  --president-500: #17304e;
  --president-600: #0f2947;
  --president-700: #092240;
  --president-800: #041b37;
  --president-900: #00142c;
  --president-950: #000e20;

  --grey-50: #fafafa;
  --grey-100: #f2f2f2;
  --grey-200: #dbdbdb;
  --grey-300: #c4c4c4;
  --grey-400: #acacac;
  --grey-500: #989898;
  --grey-600: #848484;
  --grey-700: #707070;
  --grey-800: #5b5b5b;
  --grey-900: #474747;
  --grey-950: #333333;

  --gold-50: #fffcf4;
  --gold-100: #fff0cd;
  --gold-200: #ffe0aa;
  --gold-300: #facf8d;
  --gold-400: #efbc74;
  --gold-500: #dea860;
  --gold-600: #cc9249;
  --gold-700: #b77b34;
  --gold-800: #9c6424;
  --gold-900: #7a4d16;
  --gold-950: #59370c;

  --vermillon-50: #fff4f2;
  --vermillon-100: #ffd9d4;
  --vermillon-200: #ffb0a6;
  --vermillon-300: #fb8676;
  --vermillon-400: #ee6958;
  --vermillon-500: #dc513f;
  --vermillon-600: #ce3e2b;
  --vermillon-700: #bd2d1a;
  --vermillon-800: #a31f0d;
  --vermillon-900: #851403;
  --vermillon-950: #660b00;

  --purple-50: #faf7fc;
  --purple-100: #e0cfea;
  --purple-200: #c5aad6;
  --purple-300: #aa88bf;
  --purple-400: #8e68a6;
  --purple-500: #724a8a;
  --purple-600: #633a7c;
  --purple-700: #542c6d;
  --purple-800: #45205b;
  --purple-900: #351548;
  --purple-950: #250d33;

  --petrol-50: #f7fbfd;
  --petrol-100: #cfebfa;
  --petrol-200: #9bcee8;
  --petrol-300: #63a7c7;
  --petrol-400: #3286aa;
  --petrol-500: #096489;
  --petrol-600: #005b81;
  --petrol-700: #005075;
  --petrol-800: #004366;
  --petrol-900: #003653;
  --petrol-950: #00263c;

  --rivergreen-50: #f2fbf9;
  --rivergreen-100: #c2eae3;
  --rivergreen-200: #91d5ca;
  --rivergreen-300: #65bdae;
  --rivergreen-400: #3da190;
  --rivergreen-500: #198270;
  --rivergreen-600: #0e7b68;
  --rivergreen-700: #05715f;
  --rivergreen-800: #006554;
  --rivergreen-900: #005747;
  --rivergreen-950: #004739;

  --green-50: #f2fdf9;
  --green-100: #c2f2e2;
  --green-200: #96e3ca;
  --green-300: #6fd0b0;
  --green-400: #4cb995;
  --green-500: #2d9d78;
  --green-600: #1d916b;
  --green-700: #10815c;
  --green-800: #076f4c;
  --green-900: #00593c;
  --green-950: #00402a;

  --orange-50: #fffaf2;
  --orange-100: #ffe7c2;
  --orange-200: #ffd391;
  --orange-300: #ffbf61;
  --orange-400: #ffaa3c;
  --orange-500: #f29423;
  --orange-600: #e6830d;
  --orange-700: #cc7200;
  --orange-800: #cc7200;
  --orange-900: #994f00;
  --orange-950: #803e00;

  --red-50: #fff5f5;
  --red-100: #ffc6ca;
  --red-200: #ff9ea3;
  --red-300: #ff7b82;
  --red-400: #f55f66;
  --red-500: #e34850;
  --red-600: #da333c;
  --red-700: #cc212a;
  --red-800: #b3121b;
  --red-900: #99060f;
  --red-950: #800005;

  --blue-50: #f5f9ff;
  --blue-100: #c4d9ff;
  --blue-200: #93b8fc;
  --blue-300: #6296f0;
  --blue-400: #3175dd;
  --blue-500: #0b54c2;
  --blue-600: #0045b7;
  --blue-700: #0037a6;
  --blue-800: #002a90;
  --blue-900: #001e70;
  --blue-950: #00144d;

  --yellow-50: #fffeea;
  --yellow-100: #fff9c5;
  --yellow-200: #fff485;
  --yellow-300: #ffe846;
  --yellow-400: #ffd81b;
  --yellow-500: #ffb800;
  --yellow-600: #e28d00;
  --yellow-700: #bb6302;
  --yellow-800: #984c08;
  --yellow-900: #7c3f0b;
  --yellow-950: #482000;
}
