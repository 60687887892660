gallery {
  @apply bg-transparent !important;
}

gallery-thumb {
  @apply mr-sm rounded-3 p-0 opacity-100 !important;
}

gallery-thumb.g-active-thumb {
  @apply border-2 border-president-500;
}

lightbox {
  @apply shadow-none !important;
}

lightbox gallery {
  @apply bg-black !important;
}

.g-nav-prev {
  transform: translateY(-50%) perspective(1px) scaleX(-1) !important;
}

.g-nav-next,
.g-nav-prev {
  height: 32px !important;
  padding: 8px;
}

.g-btn-close {
  height: 30px !important;
  width: 30px !important;
  padding: 8px;
}

gallery-nav svg,
.g-btn-close svg {
  @apply opacity-100 !important;
}

bgo-gallery .image_description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
lightbox .image_description {
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none;
}
